import React, { useState } from "react";
import { Alert } from "reactstrap";
import useEventListener from "../../lib/EventEmitter/useEventListener";

const AlertEvent = ({
  onEvent = "",
  defaultText = "",
  variant = "success",
  startOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  const [alertText, setAlertText] = useState(defaultText);

  console.log("onEvent", onEvent);
  useEventListener(
    (eventText) => {
      if (typeof eventText === "string") {
        setAlertText(eventText);
      }
      setIsOpen(true);
    },
    [onEvent]
  );

  return (
    <Alert isOpen={isOpen} toggle={() => setIsOpen(false)} color={variant}>
      {alertText}
    </Alert>
  );
};

export default AlertEvent;
